import { Link } from 'gatsby'
import React from 'react'
import Layout from '../components/layout'


const NotFoundPage = () => (
  <Layout>
    <h1>404 GELATO NOT FOUND</h1>
    <p>La pagina da te cercata non esiste, torna alla <Link to="/">HOME</Link></p>
  </Layout>
)

export default NotFoundPage
